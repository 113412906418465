import React, { Component } from 'react';
import { Row } from 'reactstrap';
import styles from './WantHelp.module.css';
import Header2 from '../../general/Header2';
import Wrap from '../../general/Wrap';
import Paragraph from '../../general/Paragraph';
import WantHelpForm from './WantHelpForm';

export default class WantHelp extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Wrap>
                <Row>
                    <Header2>Chci pomáhat</Header2>
                </Row>
                <Paragraph>
                    Pomáhat je v silách nás všech. Pokud jste se rozhodli přispět jakýmkoliv darem, odměnou Vám bude radost, že jste svět udělali o kus lepší. Za Váš dar děkujeme a jsme vděční.
                </Paragraph>
                <Paragraph>
                    Firemní sponzorství – stanete se partnerem Nadačního fondu při poskytování podpory. Neváhejte nás kontaktovat, máme připravenu zajímavou sponzorskou nabídku.
                </Paragraph>
                <Paragraph>
                    Registrujte se mezi dárce Nadace Cesta ke hvězdám. Po připsání daru na náš účet vám zašleme potvrzení, které můžete uplatnit ve vašem účetnictví.
                </Paragraph>
                <div className={styles.button}>
                    <WantHelpForm />
                </div>
            </Wrap>
        );
    }
}