import React from 'react';

const Logo = props => (
  <svg version={1} {...props} viewBox="0 0 90 87"> 
    <path fill="currentColor" fillOpacity="1" fillRule="nonzero" stroke="none" d="M20.01173593046876,72.60028666215021 C20.01173593046876,72.60028666215021 11.622402806868763,85.22695301315021 17.722402654368764,86.49095298155021 C23.822402501868766,87.7509529500502 43.990401997668755,72.45628666575021 43.990401997668755,72.45628666575021 C34.61040223216875,73.17628664775022 25.686402455268762,72.9242866540502 20.01173593046876,72.60028666215021 " strokeWidth="0" />
    <path fill="currentColor" fillOpacity="1" fillRule="nonzero" stroke="none" d="m21.506537732291406,63.53122052331696 c16.4893329211,1.2706666349000002 44.706665549,0.5853333186999999 46.4079988398,-5.8066665215 l-0.4159999896000001,-2.3293332751 l0.3866666570000002,-0.3799999905000001 c-2.8359999290999998,-7.7293331401 -32.8173325129,-3.5226665786000004 -45.075998873100005,-12.0986663642 c-9.9773330839,-6.7959998301 3.0999999225000003,-11.406666381500001 20.3813328238,-13.661332991800002 c17.5946662268,-2.2959999426 -3.946666568,-4.7639998809 -3.946666568,-4.7639998809 c0,0 16.729332915100002,-0.6173333179 15.5426662781,4.6119998847 c-1.1839999704000002,5.2293332026 -33.7933324885,4.1559998961 -27.3986659817,9.5719997607 c6.397333173400001,5.415999864600001 29.837332587400002,4.814666546300001 35.7493324396,5.9959998501000005 c2.613333268,0.5226666536000002 7.266666485000001,1.9253332851999998 10.779999730500002,4.3853332237 c0,0 15.693332941,-9.789333088600001 15.786666272,-15.5946662768 c0.09466666430000005,-5.8039998549 -30.9133325605,-4.282666559600001 -30.9133325605,-4.282666559600001 c0,0 -6.9013331608,-19.3959995151 -14.0013329833,-27.897332635900003 C37.6905373276914,-7.220777707883045 31.162537490891395,29.357888044316958 31.162537490891395,29.357888044316958 c0,0 -27.4839993129,-0.6786666497 -30.858665895199998,4.6946665493 C-3.0721283199086002,39.424554459316965 22.795871033391393,55.69122071931696 22.795871033391393,55.69122071931696 z" strokeWidth="0" />
    <path fill="currentColor" fillOpacity="1" fillRule="nonzero" stroke="none" d="M46.19600041258087,72.27215149782754 C46.19600041258087,72.27215149782754 65.40399993238088,86.18281781672754 72.97866640968087,86.12681781812753 C80.55466622028086,86.07081781952753 69.56799982828088,67.00015162962754 69.56799982828088,67.00015162962754 C62.826666663480864,69.94815155592754 54.42133354028087,71.50681818362753 46.19600041258087,72.27215149782754 " strokeWidth="0" />
  </svg>
);

export default Logo;