import React from 'react';
import styles from './HelpForm.module.css';
import { Alert, Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { Formik } from 'formik';
import LoadingOverlay from 'react-loading-overlay';
import * as Yup from 'yup';
import * as Constants from '../../Constants';
import formatBytes from '../../general/SizeFormatter';
import getFileAsBase64 from '../../general/FileReader';
import PrivacyPolicy from './PrivacyPolicy';

export default class NeedHelpForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            response: false,
            isLoading: false,
            isPpOpen: false,
        };

        this.toggle = this.toggle.bind(this);
    }

    submit = async (values) => {
        this.setState({ ...this.state, isLoading: true })
        let serializableValues = { ...values, type: Constants.TYPEDONEE };
        if (values.file) {
            serializableValues.file = await getFileAsBase64(values.file);
            //console.log(serializableValues);
        }
        const response = await fetch(Constants.TOBOGAURL + '/nadace/submit', {
            method: 'post',
            body: JSON.stringify(serializableValues),
            headers: { 'Content-Type': 'application/json' }
        });
        response.json().then((json) => {
            this.setState({ ...this.state, response: json });
        });
    }

    handlePpToggle = () => {
        this.setState({ ...this.state, isPpOpen: !this.state.isPpOpen });
    }

    handleSuccess = () => {
        this.setState({ ...this.state, isLoading: false });
    }

    toggle = () => {
        this.setState({ ...this.state, isLoading: false, modal: !this.state.modal, response: false });
    }

    render() {
        return (
            <div>
                <Button className={styles.button + ' gradient-bg'} onClick={this.toggle}>Potřebuji pomoc</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} backdrop="static">
                    <ModalHeader toggle={this.toggle}>Potřebuji pomoc</ModalHeader>
                    <ModalBody>
                        {this.state.response && this.state.response.success ? this.renderSuccess() : this.renderForm()}
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    renderError() {
        return (
            <div>
                <Alert color="danger">{this.state.response.message}</Alert>
            </div>
        );
    }

    renderSuccess() {
        return (
            <div>
                <Alert color="success">Děkujeme za Vaše rozhodnutí, odvahu a přejeme vám hodně štěstí!</Alert>
                <div className="text-right">
                    <Button className={'gradient-bg'} onClick={this.toggle}>Ok</Button>
                </div>
            </div>
        );
    }

    renderForm() {
        return (
            <div>
                <p>
                    Registrujte se mezi žadatele o finanční dotaci z rozpočtu Nadace Cesta ke hvězdám.
                    Po odeslání formuláře vám zašleme informační email s registračním číslem Vaší žádosti potvrzující její přijetí.
                </p>
                <LoadingOverlay active={this.state.isLoading} spinner>
                    {this.state.response && !this.state.response.success && this.renderError()}
                    <Formik
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            this.submit(values).finally(() => {
                                setSubmitting(false);
                                if (this.props.response && this.props.response.success) {
                                    resetForm();
                                }
                                this.handleSuccess();
                            });
                        }}
                        initialValues={{ email: '', name: '', phone: '', address: '', ppconfirm: false, field: '', file: null }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string()
                                .required('Povinné pole')
                                .email('Není validní email'),
                            name: Yup.string()
                                .required('Povinné pole'),
                            phone: Yup.string()
                                .required('Povinné pole'),
                            address: Yup.string()
                                .required('Povinné pole'),
                            field: Yup.string()
                                .required('Povinné pole'),
                            ppconfirm: Yup.bool()                                
                                .required('Povinné pole')
                                .oneOf([true], 'Povinné pole'),
                            file: Yup.mixed()
                                .test('fileSize', 'Maximální velikost je ' + formatBytes(Constants.MAXFILESIZE), value => {
                                    //console.log(value); 
                                    return value == null || value.size <= Constants.MAXFILESIZE;
                                })
                                .test('fileFormat', 'Nepovolený typ souboru', value => {
                                    //console.log(value); 
                                    return value == null || Constants.ALLOWEDFILETYPES.includes(value.type);
                                }),
                            })}
                    >
                        {props => {
                            const {
                                touched,
                                errors,
                                isSubmitting,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue
                            } = props;
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <FormGroup>
                                        {errors.name && touched.name && <div className={styles.validationerror}>{errors.name}</div>}
                                        <Label for="name">Jméno a příjmení/Název firmy</Label>
                                        <Input type="text" name="name" onChange={handleChange} onBlur={handleBlur} />
                                    </FormGroup>
                                    <FormGroup>
                                        {errors.email && touched.email && <div className={styles.validationerror}>{errors.email}</div>}
                                        <Label for="email">Email</Label>
                                        <Input type="email" name="email" onChange={handleChange} onBlur={handleBlur} />
                                    </FormGroup>
                                    <FormGroup>
                                        {errors.phone && touched.phone && <div className={styles.validationerror}>{errors.phone}</div>}
                                        <Label for="phone">Telefon</Label>
                                        <Input type="text" name="phone" onChange={handleChange} onBlur={handleBlur} />
                                    </FormGroup>
                                    <FormGroup>
                                        {errors.address && touched.address && <div className={styles.validationerror}>{errors.address}</div>}
                                        <Label for="address">Adresa</Label>
                                        <Input type="text" name="address" onChange={handleChange} onBlur={handleBlur} />
                                    </FormGroup>
                                    <FormGroup>
                                        {errors.field && touched.field && <div className={styles.validationerror}>{errors.field}</div>}
                                        <Label for="field">Oblast podpory</Label>
                                        <Input type="text" name="field" onChange={handleChange} onBlur={handleBlur} />
                                    </FormGroup>
                                    <FormGroup>
                                        {errors.file && touched.file && <div className={styles.validationerror}>{errors.file}</div>}
                                        <Label for="file">Soubor (podrobnější popis k žádosti, fotografie, aj.)</Label>
                                        <Input type="file" name="file" onChange={event => setFieldValue("file", event.currentTarget.files[0])} onBlur={handleBlur} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className={styles.checkbox} for="ppconfirm">
                                        {errors.ppconfirm && touched.ppconfirm && <div className={styles.validationerror}>{errors.ppconfirm}</div>}
                                            <Input type="checkbox" name="ppconfirm" onChange={handleChange} onBlur={handleBlur} />
                                            Souhlasím se zpracováním <span className={styles.link} onClick={this.handlePpToggle}>osobních údajů</span>
                                            <PrivacyPolicy isOpen={this.state.isPpOpen} toggle={this.handlePpToggle} />
                                        </Label>
                                    </FormGroup>
                                    <div className="text-right">
                                        <Button className="gradient-bg" type="submit" disabled={isSubmitting}>Odeslat žádost</Button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </LoadingOverlay>
            </div>
        );
    }
}