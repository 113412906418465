export const TOBOGAURL = 'https://www.toboga.cz';
export const MAXFILESIZE = 3145728; // 3MB
export const TYPEDONOR = 'Donor';
export const TYPEDONEE = 'Donee';
export const ALLOWEDFILETYPES = [
    'application/pdf',
    'application/zip',
    'application/x-zip-compressed',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'image/png'];