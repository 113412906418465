import React, { Component } from 'react';
import Paragraph from '../../general/Paragraph';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

export default class PrivacyPolicy extends Component {

    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} backdrop={true} size="lg">
                    <ModalHeader toggle={this.toggle}>Zásady ochrany osobních údajů nadačního fondu Cesta ke hvězdám</ModalHeader>
                    <ModalBody>
                        <Paragraph>
                            Souhlasím v souladu se zákonem č. 101/2000 Sb., zákon o ochraně osobních údajů, ve znění pozdějších předpisů, se shromažďováním, uchováním a zpracováním osobních údajů v rozsahu jména a příjmení, adresy bydliště, e-mailové adresy a telefonního čísla správcem Nadace Cesta ke hvězdám IČO 04653793 (příp. osobou zpracovávající osobní údaje z pověření správce), za účelem vedení databáze přispěvatelů, smluvních partnerů a obdarovaných a komunikace s nimi, zejm. poskytování informací o realizaci projektů, o možnostech podpory a o aktivitách Nadace Cesta ke hvězdám, za účelem poskytování informací pro daňové účely při vystavování potvrzení o poskytnutých příspěvcích, pro účely výroční zprávy nadačního fondu a v rámci jiného zveřejňování aktivit nadačního fondu v rozsahu jména a příjmení, za účelem pořádání odborných kurzů, školení a jiných vzdělávacích akcí, dále pro účely mimoškolní výchovy a vzdělání a pro účely pořádání kulturních, rekreačních, sportovních a společenských aktivit. Souhlas poskytuji dobrovolně a na dobu, která je nezbytná k účelu zpracování osobních údajů, maximálně však na dobu deseti (10) let. Beru na vědomí, že mám práva dle ustanovení § 11 až § 21 zákona o ochraně osobních údajů, tj. zejména, že svůj souhlas mohu bezplatně kdykoliv odvolat, že mám právo přístupu k osobním údajům, právo na jejich opravu, likvidaci či právo nesprávné osobní údaje blokovat.
                            </Paragraph>
                        <Paragraph>
                            Správce prohlašuje, že bude shromažďovat osobní údaje v rozsahu nezbytném pro naplnění stanoveného účelu a zpracovávat je pouze v souladu s účelem, k němuž byly shromážděny. Osoby zpracovávající osobní údaje z pověření správce jsou povinni zachovávat mlčenlivost o osobních údajích, a to i po skončení pracovního poměru nebo prací.
                        </Paragraph>
                        <div className="text-center">
                            <Button className="gradient-bg" onClick={this.props.toggle}>Zavřít</Button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}