import React, { Component } from 'react';
import styles from './BgImage.module.css';
import PropTypes from 'prop-types';

export default class BgImage extends Component {

    render() {
        var style = {
            backgroundImage: (this.props.dim ? 'linear-gradient(rgba(0, 0, 0, ' + this.props.dim + '), rgba(0, 0, 0, ' + this.props.dim + ')), ' : '') + 
                'url(' + this.props.url + ')',
            height: this.props.height + 'px',
            backgroundSize: this.props.type !== undefined ? this.props.type : types.contain
        }

        return (
            <div className={styles.img_container + " " + this.props.className} style={style}></div>
        );
    }
}

const types = {
    cover: "cover", 
    contain: "contain"
}

BgImage.types = types;

BgImage.propTypes = {
    optionalType: PropTypes.oneOf(Object.keys(types))
}
