import React, { Component } from 'react';

export default class MainMenuItem extends Component {

  scrollTo = (path) => {
    this.props.history.replace('/' + path);
  }

  isActive = () => {
    return window.location.pathname === '/' + this.props.path;
  }

  render() {
    return (
        <span onClick={() => this.scrollTo(this.props.path)} className={"nav-link " + (this.isActive() ? "active" : "")}>{this.props.label}</span>
    );
  }
}