import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from "react-router";
import { Component } from 'react';
import { observer, inject } from 'mobx-react';

@inject('ScrollStore')
@observer
class ScrollOnUserChange extends Component {

  componentDidMount = () => {
    window.addEventListener("scroll", this.onScroll, true);
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.onScroll, true);
  };

  onScroll = (e) => {
    if (!this.props.ScrollStore.isRouterScrolling) {

      if (!this.node) {
        this.node = ReactDOM.findDOMNode(this);
      }
      var rectangle = this.node.getBoundingClientRect();
      if (window.location.pathname !== this.props.pathname && rectangle.top < 500 && rectangle.top > 300) {
        this.props.ScrollStore.setUserScrolling(true);
        this.props.history.replace(this.props.pathname);
      }
    }

    clearTimeout(this.scrollTimeout);
    this.scrollTimeout = setTimeout(() => {
      //console.log('User scrolling ended');
      this.props.ScrollStore.setUserScrolling(false);
      this.props.ScrollStore.setRouterScrolling(false);
    }, 100);
  }

  render() {
    return (
      <div id={this.props.pathname.substring(1)}>
        {this.props.children}
      </div>
    );
  }
}

export default withRouter(ScrollOnUserChange);