import React, { Component } from 'react';
import { Row } from 'reactstrap';
import Header2 from '../../general/Header2';
import Wrap from '../../general/Wrap';
import DreamsItem from './DreamsItem';
import { observer, inject } from 'mobx-react';

@inject('DreamsStore')
@observer
class Dreams extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Wrap>
                <Row>
                    <Header2>Splněné sny</Header2>
                </Row>
                <Row>
                    {this.props.DreamsStore.items.map((item) => {
                        return (<DreamsItem item={item} key={item.id} />)
                    })}
                </Row>
            </Wrap>
        );
    }
}

export default Dreams