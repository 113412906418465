import React, { Component } from 'react';
import { Row } from 'reactstrap';
import Header2 from '../../general/Header2';
import Wrap from '../../general/Wrap';
import PartnersItem from './PartnersItem';
import { observer, inject } from 'mobx-react';
import styles from './Partners.module.css';

@inject('PartnersStore')
@observer
class Partners extends Component {

    render() {
        return (
            <div className={styles.bg}>
                <Wrap>
                    <Row>
                        <Header2>Partneři</Header2>
                    </Row>
                    <Row>
                        {this.props.PartnersStore.items.map((item) => {
                            return (<PartnersItem item={item} key={item.id} />)
                        })}
                    </Row>
                </Wrap>
            </div>
        );
    }
}

export default Partners