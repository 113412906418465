import React, { Component } from 'react';
import { Col } from 'reactstrap';
import styles from './DreamsItem.module.css';
import Paragraph from '../../general/Paragraph';
import ImageGrid from '../../general/ImageGrid';

export default class DreamsItem extends Component {

    render() {
        return (
            <Col xs="12" sm="6" md="4" lg="4">
                <div className={styles.item}>
                    <div className={styles.item_content}>
                        <h3 className={styles.name}>{this.props.item.title}</h3>
                        <Col xs="12">
                            {this.props.item.images != null && <ImageGrid images={this.props.item.images} />}
                        </Col>
                        <Paragraph className={styles.description} html={this.props.item.text} />
                    </div>
                </div>
            </Col>
        );
    }
}