import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import styles from './Contact.module.css';
import Header2 from '../../general/Header2';
import Header3 from '../../general/Header3';
import Wrap from '../../general/Wrap';

export default class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Wrap>
                <Row>
                    <Header2>Kontakt</Header2>
                </Row>
                <Row>
                    <Col sm="3">
                        <div className={styles.contact}>
                            <Header3>Cesta ke hvězdám</Header3>
                            <div>IČO: 04653793</div>
                            <div>Zlín, Kostelec 679</div>
                            <div>Číslo účtu: 9086414001/5500</div>
                        </div>
                    </Col>
                    <Col sm="3">
                        <div className={styles.contact}>
                            <Header3>Kontakt</Header3>
                            <div><a href="mailto:nadace@cestakehvezdam.cz">nadace@cestakehvezdam.cz</a></div>
                            <div>+420 608 500 208</div>
                            <div>+420 777 204 321</div>
                        </div>
                    </Col>
                    <Col sm="3">
                        <div className={styles.contact}>
                            <Header3>Zakladatelé</Header3>
                            <div>Tomáš Valášek</div>
                            <div>Mgr. Jana Valášková</div>
                        </div>
                    </Col>
                    <Col sm="3">
                        <div className={styles.contact}>
                            <Header3>Právní forma</Header3>
                            <div>Nadační fond Cesta ke hvězdám je registrován od 17.12.2015 pod spisovou značkou 582 N u Krajského soudu v Brně</div>
                        </div>
                    </Col>
                </Row>
            </Wrap>
        );
    }
}
