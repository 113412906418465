import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import styles from './Header1.module.css';

export default class Header1 extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Row>
                <Col xs="12">
                    <h1 className={styles.header1}>{this.props.children}</h1>
                </Col>
            </Row>
        );
    }
}
