import React, { Component } from 'react';
import styles from './Stars.module.css';

class Stars extends Component {

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.stars}></div>
        <div className={styles.stars2}></div>
        <div className={styles.stars3}></div>
        <div className={styles.title}>
          <div>
            {/* PARALLAX PIXEL STARS */}
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default Stars