import React, { Component } from 'react';
import { Row } from 'reactstrap';
import styles from './NeedHelp.module.css';
import Header2 from '../../general/Header2';
import Wrap from '../../general/Wrap';
import Paragraph from '../../general/Paragraph';
import NeedHelpForm from './NeedHelpForm';

export default class NeedHelp extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Wrap>
                <Row>
                    <Header2>Potřebuji pomoc</Header2>
                </Row>
                <Paragraph>
                    Jsme tady právě pro vás. Dotační podpora je poskytována v souladu s posláním a účelem Nadačního fondu. Nadační fond Cesta ke hvězdám poskytuje investiční a neinvestiční dotace:  ve formě finančních prostředků, konkrétních věcí i služeb. Dotace mohou mít charakter jednorázového i opětovného plnění. Nabízíme také záštitu nad různými projekty, která vždy závisí na aktuálních možnostech nadačního fondu.
                </Paragraph>
                <Paragraph>
                    Pokud jste si pročetli Priority naší pomoci a našli jste se alespoň v jedné oblasti, neváhejte se na nás obrátit a vyplnit náš formulář.
                </Paragraph>
                <Paragraph>
                    Vyplněním formuláře jste udělali první krok. Součástí formuláře je také sekce soubor, zde prosím přiložte přílohu, její součástí by měly být podrobnější informace, případně fotografie, aj. Žádosti se zpracovávají postupně a jsou vždy předloženy při následujícím schvalování komisí. Zasílat je můžete celoročně. Při potřebě doplnění informací budete vyzváni a o výsledku schvalování budete informováni. Komise zasedá jednou za čtvrt roku, v případě aktuální potřeby i dříve.
                </Paragraph>
                <div className={styles.button}>
                    <NeedHelpForm />
                </div>
            </Wrap >
        );
    }
}
