import React, { Component } from 'react';
import './App.module.css';
import MainMenu from './menu/MainMenu';
import MainPage from './pages/MainPage/MainPage';
import { BrowserRouter as Router, Route } from "react-router-dom";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <div>
            <MainMenu history={this.props.history} />
            <Route path="/" exact component={MainPage} />
            <Route path="/o-nadacnim-fondu/" component={MainPage} />
            <Route path="/aktuality/" component={MainPage} />
            <Route path="/chci-pomahat/" component={MainPage} />
            <Route path="/potrebuji-pomoc/" component={MainPage} />
            <Route path="/splnene-sny/" component={MainPage} />
            <Route path="/kontakt/" component={MainPage} />
            <Route path="/partneri/" component={MainPage} />
            {/* <Route path="/admin/" component={Admin} /> */}
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
