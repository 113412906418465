import React, { Component } from 'react';
import { Col } from 'reactstrap';
import styles from './PartnersItem.module.css';
import BgImage from '../../general/BgImage';
import * as Constants from '../../Constants';
import { hasWebpSupport } from '../../general/SupportDetection';

export default class PartnersItem extends Component {
    
    getUrl(url) {
        return Constants.TOBOGAURL + url.replace('/jpg', (hasWebpSupport() ? '/webp' : '/jpg'));
    }

    render() {
        return (
            <Col xs="6" sm="6" md="4" lg="3">
                <div className={styles.item}>
                    <a className={styles.a} href={this.props.item.url} target="_blank" rel="noopener noreferrer">
                        <div className={styles.name}>{this.props.item.name}</div>
                        {this.props.item.logo != null &&
                            <div className={styles.img_container}>
                                <BgImage url={this.getUrl(this.props.item.logo.url)} height={200} type={BgImage.types.contain} />
                            </div>
                        }
                    </a>
                </div>
            </Col>
        );
    }
}