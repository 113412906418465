import React, { Component } from 'react';
import { Collapse, Navbar, NavbarToggler, Nav, NavItem, NavbarBrand } from 'reactstrap';
import { withRouter } from 'react-router-dom'
import MainMenuItem from './MainMenuItem';
import Logo from '../general/Logo';
//import { ReactComponent as MainIcon } from './resources/logo.svg';

class MainMenu extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    document.addEventListener('click', this.handleDocumentClick, true);

    this.state = {
      isOpen: false
    };
  }

  scrollTop() {
    this.props.history.replace('/');
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  handleDocumentClick = (e) => {
    const container = this._element;
    if (e.target !== container && !container.contains(e.target) && this.state.isOpen) {
      this.toggle();
    }
  }

  render() {
    return (
      <div>
        <Navbar color="light" light expand="md" fixed="top">
          <NavbarBrand onClick={() => this.scrollTop()}>
            <div className="home zoom text-blue">
              <Logo width={30} height={30} />
            </div>
          </NavbarBrand>
          <div ref={(c) => (this._element = c)}>
            <NavbarToggler onClick={this.toggle} />
          </div>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <MainMenuItem path="o-nadacnim-fondu" label="O nadačním fondu" history={this.props.history} />
              </NavItem>
              <NavItem>
                <MainMenuItem path="chci-pomahat" label="Chci pomáhat" history={this.props.history} />
              </NavItem>
              <NavItem>
                <MainMenuItem path="potrebuji-pomoc" label="Potřebuji pomoc" history={this.props.history} />
              </NavItem>
              <NavItem>
                <MainMenuItem path="splnene-sny" label="Splněné sny" history={this.props.history} />
              </NavItem>              
              <NavItem>
                <MainMenuItem path="kontakt" label="Kontakt" history={this.props.history} />
              </NavItem>              
              <NavItem>
                <MainMenuItem path="partneri" label="Partneři" history={this.props.history} />
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default withRouter(MainMenu);