import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';
import styles from './Callout.module.css';
import CalloutItem from './CalloutItem';

export default class Callout extends Component {

    render() {
        return (
            <Container fluid={true} id={styles.wrap}>
                <Row>
                    <Container fluid={false}>
                        <Row>
                            <CalloutItem title="Radost" text="Máme radost z dávání a podpory dobrých projektů a umožňujeme to díky nadaci i druhým"></CalloutItem>
                            <CalloutItem title="Respekt" text="Výše daru nerozhoduje, ctíme a vážíme si všech"></CalloutItem>
                            <CalloutItem title="Do poslední koruny" text="Všechny přijaté prostředky jsou do poslední koruny využity v projektech pro potřebné příjemce. Veškeré provozní náklady nadace jsou hrazeny ze soukromých financí zakladatelů a dalších filantropů"></CalloutItem>
                            <CalloutItem title="Přehledná pomoc" text="Každý dárce může kdykoliv dohledat v jakém projektu byl jeho dar použit"></CalloutItem>
                        </Row>
                        {/* <Row>
                            <Col md="6">
                                <div className={styles.callout + " callout-item"}>
                                    <Logo width={120} height={120} className="icon" />
                                    <div className={styles.name}>Chci pomáhat</div>
                                    <div className={styles.date}>25. – 26. 5. 2019</div>
                                    <p className={styles.text}>Pomáhat je v silách nás všech. Pokud jste se rozhodli přispět jakýmkoliv darem, odměnou Vám bude radost, že jste svět udělali o kus lepší. Za Váš dar děkujeme a jsme vděční. </p>
                                    <WantHelpForm />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className={styles.callout + " callout-item"}>
                                    <Logo width={120} height={120} className="icon" />
                                    <IcoMoon icon="cloud" />
                                    <div className={styles.name}>Potřebuji pomoc </div>
                                    <div className={styles.date}>25. 5. 2019</div>
                                    <p className={styles.text}>Jsme tady právě pro vás. Nabízíme investiční a neinvestiční pomoc ve formě: finančních prostředků, konkrétních věcí, služeb a také záštitu nad různými projekty.
Podpora je vždy poskytována v souladu s posláním a účelem Nadačního fondu.</p>
                                    <NeedHelpForm />
                                </div>
                            </Col>
                        </Row> */}
                    </Container>
                </Row>
            </Container>
        );
    }
}