import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import smoothscroll from 'smoothscroll-polyfill';

@inject('ScrollStore')
@observer
class ScrollOnRouterChange extends Component {

    menuOffset = 60;

    constructor(props) {
        super(props);
        smoothscroll.polyfill();
      }
    
    componentDidMount() {
        if (this.props.ScrollStore.isUserScrolling) {
            return;
        }

        const id = window.location.pathname.substr(1);
        if (id.length > 1) {
            const element = document.getElementById(id);
            if (element) {
                this.scrollToOffset(element.offsetTop);
            }
        } else if (id.length === 0) {
            this.scrollToOffset(0);
        }
    }

    scrollToOffset(offset) {
        this.props.ScrollStore.setRouterScrolling(true);

        window.scrollTo({
            top: offset - this.menuOffset,
            behavior: 'smooth'
        });
    }

    render() {
        return (
            <div>
                {this.props.children}
            </div>
        );
    }
}

export default ScrollOnRouterChange;