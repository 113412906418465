import React, { Component } from 'react';
import ScrollOnUserChange from '../../general/ScrollOnUserChange';
import Partners from './Partners';
import Callout from './Callout';
import Contact from './Contact';
import Header from './Header';
import WantHelp from './WantHelp';
import NeedHelp from './NeedHelp';
import { observer, inject } from 'mobx-react';
import ScrollOnRouterChange from '../../general/ScrollOnRouterChange';
import Dreams from './Dreams';
import About from './About';

@inject('ScrollStore')
@observer
class MainPage extends Component {

    render() {
        return (
            <div>
                <ScrollOnRouterChange>
                    <Header />
                    <Callout />
                    <ScrollOnUserChange pathname="/o-nadacnim-fondu">
                        <About />
                    </ScrollOnUserChange>
                    <ScrollOnUserChange pathname="/chci-pomahat">
                        <WantHelp />
                    </ScrollOnUserChange>
                    <ScrollOnUserChange pathname="/potrebuji-pomoc">
                        <NeedHelp />
                    </ScrollOnUserChange>
                    <ScrollOnUserChange pathname="/splnene-sny">
                        <Dreams />
                    </ScrollOnUserChange>
                    <ScrollOnUserChange pathname="/kontakt">
                        <Contact />
                    </ScrollOnUserChange>                    
                    <ScrollOnUserChange pathname="/partneri">
                        <Partners />
                    </ScrollOnUserChange>
                </ScrollOnRouterChange>
            </div>
        );
    }
}

export default MainPage