import React, { Component } from 'react';
import { Col } from 'reactstrap';
import styles from './CalloutItem.module.css';
import Logo from '../../general/Logo';

export default class CalloutItem extends Component {

    render() {
        return (
            <Col md="3">
                <div className={styles.container}>
                    <Logo width={60} height={60} className="icon" />
                    <strong className={styles.title}>{this.props.title}</strong>
                    <p className={styles.text}>{this.props.text}</p>
                </div>
            </Col>
        );
    }
}