export class ScrollStore {

    constructor() {
        this.isUserScrolling = false;
        this.isRouterScrolling = false;
    }

    setUserScrolling = (state) => {
        this.isUserScrolling = state;
    }

    setRouterScrolling = (state) => {
        this.isRouterScrolling = state;
    }
}