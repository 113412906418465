import React, { Component } from 'react';
import styles from './ImageGrid.module.css';
import BgImage from './BgImage';
import * as Constants from '../Constants';
import { Col, Row } from 'reactstrap';
import { PhotoSwipe } from 'react-photoswipe';
import { hasWebpSupport } from './SupportDetection';

export default class ImageGrid extends Component {

    height = 230;
    width = 350;

    constructor(props) {
        super(props);
        this.state = { isPsOpen: false, psOptions: { history: false, loop: false, index: 0 } };
    }

    hasCount() {
        return this.props.images != null && this.props.images.length > 3;
    }

    getWidth() {
        return this.width;
    }

    getHeight() {
        return this.props.hasOwnProperty('height') ? this.props.height : this.height;
    }

    getHalfHeight() {
        return this.getHeight() / 2;
    }

    handlePsOpen(index) {
        this.setState({ ...this.state, isPsOpen: true, psOptions: { ...this.state.psOptions, index: index } });
    }

    handlePsClose() {
        this.setState({ ...this.state, isPsOpen: false });
    }

    toPsModel(image) {
        return { src: this.getUrl(image.url), w: image.width, h: image.height, title: image.description }
    }

    getUrl(url) {
        return Constants.TOBOGAURL + url.replace('/jpg', (hasWebpSupport() ? '/webp' : '/jpg'));
    }

    render() {
        var images = this.props.images;
        if (!images || images.length === 0) {
            return ("");
        }
        return (
            <Row className={styles.ImageGrid}>

                {images.length > 0 &&
                    <Col xs={images.length === 1 ? 12 : 8}>
                        <div className={styles.link} onClick={() => this.handlePsOpen(0)}>
                            <BgImage url={this.getUrl(images[0].url) + this.getWidth() + '/'} height={this.getHeight()}
                                className={styles.img1} type={BgImage.types.cover} />
                        </div>
                    </Col>
                }

                {images.length > 1 &&
                    <Col xs={4}>
                        <div className={styles.link} onClick={() => this.handlePsOpen(1)}>
                            <BgImage url={this.getUrl(images[1].url) + 'null/' + (images.length === 2 ? this.getHeight() : this.getHalfHeight())} 
                                height={images.length === 2 ? this.getHeight() : this.getHalfHeight()}
                                className={styles.img2} type={BgImage.types.cover} />
                        </div>

                        {images.length > 2 &&
                            <div className={styles.countContainer}>
                                <div className={styles.link} onClick={() => this.handlePsOpen(2)}>
                                    <div className={styles.countValue}>+{images.length - 3}</div>
                                    <BgImage url={this.getUrl(images[2].url) + 'null/' + this.getHalfHeight()} height={this.getHalfHeight()}
                                        className={styles.img3} type={BgImage.types.cover} dim={0.4} />
                                </div>
                            </div>
                        }
                    </Col>
                }
                <PhotoSwipe isOpen={this.state.isPsOpen} items={this.props.images.map(x => this.toPsModel(x))}
                    options={this.state.psOptions} onClose={() => this.handlePsClose()} />
            </Row>
        );
    }
}
