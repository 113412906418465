import React, { Component } from 'react';
import { Col } from 'reactstrap';
import styles from './Header3.module.css';

export default class Header3 extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Col xs="12">
                <h3 className={styles.header3}>{this.props.children}</h3>
            </Col>
        );
    }
}
