import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Header1 from '../../general/Header1';
import Header2 from '../../general/Header2';
import Wrap from '../../general/Wrap';
import Paragraph from '../../general/Paragraph';

export default class About extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Wrap>
                <Header1>O Nadačním fondu</Header1>
                <Row>
                    <Col xs="12">
                        <Paragraph>
                            Nadace Cesta ke hvězdám pomáhá dětem, rodinám, sportovním i jiným kolektivům.
                            Splňujete-li některou z jmenovaných priorit, prosím neváhejte nás kontaktovat. Věříme, že investicemi do projektů a aktivit zaměřených na děti, mládež, rodiny, a smysluplně trávený volný čas budeme prospěšní jednotlivcům i celé společnosti.
                        </Paragraph>
                        <Header2>Proč zrovna Cesta ke hvězdám?</Header2>
                        <Paragraph>
                            Jak už název Nadačního fondu - Cesta ke hvězdám - napovídá - jsme tady pro to, abychom pomáhali plnit dětské i dospělé sny, a za těmi sny vede cesta. Jak mnohý zkušený poutník zjistí, každá cesta je už vlastně cíl a my vás chceme na této cestě doprovázet. V neposlední řadě, na Cestě za sny se může stát, že se nejen dotknete svých vysněných přání, ale potkáte skutečné hvězdy, které naši Nadaci podporují a které už zjistily, že pomáhat je víc než vlastnit a dávat je víc než brát. 
                        </Paragraph>
                        <Header2>Priority a zaměření naší pomoci</Header2>
                        <ul>
                            <li>Umožnit všem dětem nezávisle na jejich sociálním postavení splnění jejich cílů a snů (nejen sportovních)</li>
                            <li>Podpora sociálně slabým rodinám mladých sportovců, talentovaných studentů</li>
                            <li>Podpora žáků a sportovců, kteří mají výborné studijní a sportovní výsledky</li>
                            <li>Podpora žáků a sportovců, kteří sice nedosahují vynikajících výsledků, ale svým přístupem a poctivostí jdou příkladem ostatním</li>
                            <li>Podpora dětí i rodičů v neočekávané životní situaci</li>
                            <li>Pořádání akcí pro děti</li>
                            <li>Podpora rozvoje speciálních projektů mládeže, akcí a turnajů</li>
                            <li>Ocenění za fair-play a dobrý skutek</li>
                            <li>Podpora boje proti korupci, motivovat k poctivosti děti, rodiče, manažery, agenty i trenéry</li>
                            <li>Ocenění pedagogů, trenérů a vedoucích – hlavně asistentů a dobrovolníků</li>
                            <li>Zajištění materiálního sportovního vybavení pro vybrané děti</li>
                            <li>Podpora tělesně i mentálně postiženým dětem</li>
                            <li>Podpora myšlenky tolerance vůči jiným etnickým skupinám i imigrantům</li>
                            <li>Pořádání charitativních akcí a aukcí, jejichž výtěžky půjdou na účely nadačního fondu</li>
                        </ul>
                    </Col>
                </Row>
            </Wrap>
        );
    }
}
