import React, { Component } from 'react';
import { Col } from 'reactstrap';
import styles from './Header2.module.css';

export default class Header2 extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Col xs="12">
                <h2 className={styles.header2}>{this.props.children}</h2>
            </Col>
        );
    }
}
