async function getFileAsBase64(file) {
    const base64Content = await getContentAsBase64(file);
    return { name: file.name, size: file.size, type: file.type, base64Content: base64Content };
}

function getContentAsBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
        reader.addEventListener("load", function () {
            const base64Content = reader.result.substr(reader.result.indexOf(',') + 1);
            resolve(base64Content);
        }, false);    
    });
}

export default getFileAsBase64;