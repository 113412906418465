import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import styles from './Paragraph.module.css';

export default class Paragraph extends Component {

    render() {
        if (this.props.html) {
            return (
                <Row className={this.props.className}>
                    <Col xs="12">
                        <div className={styles.paragraph + (this.props.center ? " text-center" : "") + (this.props.white ? " text-white" : "" )} 
                            dangerouslySetInnerHTML={{ __html: this.props.html }}></div>
                    </Col>
                </Row>
            )
        }
        return (
            <Row className={this.props.className}>
                <Col xs="12">
                    <p className={styles.paragraph + (this.props.center ? " text-center" : "") + (this.props.white ? " text-white" : "" )}>{this.props.children}</p>
                </Col>
            </Row>
        );
    }
}
