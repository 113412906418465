import React, { Component } from 'react';
import { Container } from 'reactstrap';
import styles from './Header.module.css';
import Stars from '../../general/Stars';

export default class Header extends Component {

  render() {
    return (
      <Stars>
        <div id={styles.wrap} name="home">
          <Container>
            <header className={styles.header + " header"}>
              <h1 className={styles.hidden}>Cesta ke hvězdám - Nadační fond</h1>
            </header>
          </Container>
        </div>
      </Stars>
    );
  }
}
