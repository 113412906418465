import { observable } from 'mobx';
import * as Constants from '../Constants';

export class DreamsStore {

    @observable items = [];
    @observable isLoading = true;

    constructor() {
       this.load();
    }

    async load() {
        this.isLoading = true;
        fetch(Constants.TOBOGAURL + '/nadace/dreams')
            .then(response => response.json())
            .then(data => {
                data.forEach(item => this.items.push(item));
                this.isLoading = false;
            });
    }
}