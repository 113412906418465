import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';
import styles from './Wrap.module.css';

export default class Wrap extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Container fluid={true} className={styles.wrap} name={this.props.name}>
                <Row>
                    <Container>
                        {this.props.children}
                    </Container>
                </Row>
            </Container>
        );
    }
}
