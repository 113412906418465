import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "mobx-react";
import { ScrollStore } from './stores/ScrollStore';
import { DreamsStore } from './stores/DreamsStore';
import { PartnersStore } from './stores/PartnersStore';
import 'react-photoswipe/lib/photoswipe.css';

ReactDOM.render(
    <Provider PartnersStore={new PartnersStore()} DreamsStore={new DreamsStore()} ScrollStore={new ScrollStore()}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
